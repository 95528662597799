import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/layout"
import { StyledWrapper } from "../../components/wrapper"

const Banner = styled.div`
  background-color: ${props => props.theme.colors.liberty};
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    text-align: center;
  }
`

const Wrapper = styled(StyledWrapper)`
  padding: 48px 16px !important;
  font-size: 18px;

  h1,
  h2,
  h3,
  h4,
  p {
    margin-bottom: 32px;
  }

  p,
  li {
    line-height: 32px;
  }
`

const DataCategoryTable = styled.table`
  border-collapse: collapse;
  margin-bottom: 32px;

  &,
  th,
  td {
    border: 1px solid black;
  }

  th,
  td {
    padding: 8px;
  }
`

const title = "Aviso de Privacidad"

export default () => {
  useEffect(() => {
    window.location.href = "https://syntage.com/legal/aviso-de-privacidad"
  }, [])

  return (
    <Layout title={title}>
      <div style={{ display: "none" }}>
        <Banner>
          <h1>{title}</h1>
        </Banner>
        <Wrapper>
          <p>Fecha de entrada en vigor: 14 de abril de 2021</p>
          <p>
            POR FAVOR LEE CON ATENCIÓN EL PRESENTE AVISO DE PRIVACIDAD (el
            "Aviso") QUE PONEMOS A TU DISPOSICIÓN EN CUMPLIMIENTO A LA
            LEGISLACIÓN VIGENTE EN LOS ESTADOS UNIDOS MEXICANOS ("México") EN
            MATERIA DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE
            PARTICULARES.
          </p>
          <p>
            Los datos personales son la información correspondiente a una
            persona física identificada o identificable (el "
            <strong>Titular</strong>"), como por ejemplo el nombre, número de
            identificación, datos de localización, un identificador en línea,
            uno o varios elementos de la identidad física, fisiológica,
            genética, psíquica, económica, cultural o social de dicha persona ("
            <strong>Datos Personales</strong>").
          </p>
          <p>
            En SAT.ws ("<strong>SATWS</strong>"), señalando como domicilio para
            oír y recibir notificaciones el ubicado en, Calle Campeche, No. 429,
            Int. No. 6C, Col. Hipódromo Condesa, Alcaldía Cuauhtémoc, C.P.
            06170, CDMX, México sabemos lo importante que es para ti la
            protección de los Datos Personales que compartes con nosotros. El
            propósito del presente Aviso es darte a conocer cualquier operación
            o conjunto de operaciones, ya sea procedimientos automatizados o no,
            como la recaudación, registro, organización, estructuración,
            conservación, adaptación o modificación, extracción, consulta,
            utilización, comunicación por transmisión, difusión o cualquier otro
            tipo de habilitación de acceso, cotejo o interconexión, limitación,
            supresión o destrucción ("<strong>Tratamiento</strong>") de tus
            Datos Personales.
          </p>
          <p>
            En todo momento, tú eres el único Titular de los Datos Personales
            que nos proporcionas. Al aceptar el presente Aviso, nosotros
            actuamos como "<strong>Responsables</strong>" del Tratamiento de tus
            Datos Personales y nos comprometemos únicamente a recabarlos y
            utilizarlos conforme lo dispuesto en el presente Aviso y
            exclusivamente para los fines que hayas autorizado según lo aquí
            dispuesto.
          </p>
          <p>
            Si el Usuario (según se define adelante), a través de la Plataforma
            (según se define adelante), llegara a proporcionar datos personales
            de terceros; entonces, el Usuario manifiesta expresamente que cuenta
            con las debidas autorizaciones y ha dado a conocer a sus
            clientes/usuarios el aviso de conformidad correspondiente conforme a
            la Ley de Protección de Datos Personales en Posesión de Particulares
            y su Reglamento (la "<strong>Ley</strong>"). En este sentido, SATWS
            no recaba datos personales de los clientes/usuarios de los Usuarios;
            por lo que, en caso de que el Usuario proporcionara dichos datos,
            SATWS será considerado como ("<strong>Encargado</strong>" según se
            define en la Ley) y el Usuario como ("<strong>Responsable</strong>"
            según se define en la Ley).
          </p>
          <p>
            El presente Aviso aplica para cualquier sitio web, aplicaciones y
            herramientas digitales, propiedad de SATWS (la "
            <strong>Plataforma</strong>"), independientemente de la forma y el
            lugar de acceso.
          </p>
          <h2>¿Cuáles son los datos personales que recabamos?</h2>
          <p>
            En SATWS recabamos tus Datos Personales únicamente en la medida en
            la que tú nos los proporcionas. Al aceptar el presente Aviso y
            proporcionarnos tus Datos Personales estás manifestando tu
            consentimiento al Tratamiento de tus Datos Personales conforme a lo
            aquí dispuesto.
          </p>
          <p>
            Para hacer uso de nuestra Plataforma deberás haber aceptado nuestros{" "}
            <Link to={"/legal/terminos-y-condiciones"}>
              Términos y Condiciones
            </Link>
            , habernos proporcionado información a través de un formulario y
            haber una cuenta como cliente ("
            <strong>Usuario</strong>") de SATWS.
          </p>
          <DataCategoryTable>
            <thead>
              <tr>
                <th>Categoría de Datos Personales</th>
                <th>Datos</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={7}>Datos de identificación</td>
                <td>Nombre completo</td>
              </tr>
              <tr>
                <td>RFC</td>
              </tr>
              <tr>
                <td>CURP</td>
              </tr>
              <tr>
                <td>CIF</td>
              </tr>
              <tr>
                <td>CIEC</td>
              </tr>
              <tr>
                <td>E.FIRMA</td>
              </tr>
              <tr>
                <td>Datos de ubicación</td>
              </tr>
              <tr>
                <td rowSpan={2}>Datos de contacto</td>
                <td>Correo electrónico</td>
              </tr>
              <tr>
                <td>Teléfono</td>
              </tr>
              <tr>
                <td rowSpan={2}>Datos laborales</td>
                <td>Empresa donde labora</td>
              </tr>
              <tr>
                <td>Actividad económica a la que se dedica</td>
              </tr>
              <tr>
                <td>Datos académicos</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td rowSpan={7}>Datos patrimoniales o financieros</td>
                <td>Facturas (recibidas y emitidas)</td>
              </tr>
              <tr>
                <td>Declaraciones (provisionales y definitivas)</td>
              </tr>
              <tr>
                <td>Constancia de situación fiscal</td>
              </tr>
              <tr>
                <td>Opiniones de cumplimiento de obligaciones fiscales</td>
              </tr>
              <tr>
                <td>Adeudos fiscales</td>
              </tr>
              <tr>
                <td>Buzón tributario</td>
              </tr>
              <tr>
                <td>Régimen Fiscal</td>
              </tr>
              <tr>
                <td>Datos biométricos</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </DataCategoryTable>
          <p>
            En cualquier momento podrás revocar el consentimiento al presente
            Aviso conforme a lo dispuesto en la Sección denominada "
            <strong>
              MECANISMO Y PROCEDIMIENTO PARA LA REVOCACIÓN DEL CONSENTIMIENTO
            </strong>
            ".
          </p>
          <p>
            En SATWS contamos con las medidas de seguridad técnicas,
            administrativas y organizativas apropiadas para garantizar un nivel
            de seguridad que permita proteger tus Datos Personales contra
            cualquier: destrucción, pérdida, alteración accidental o ilícita, y
            comunicación o acceso no autorizado como: (i) seudoniminazación y el
            cifrado de datos personales, (ii) capacidad de garantizar la
            confidencialidad, integridad, disponibilidad y resiliencia
            permanentes de los sistemas y servicios de Tratamiento, (iii)
            capacidad de restaurar la disponibilidad y el acceso a los Datos
            Personales de forma rápida en caso de incidente físico o técnico,
            (iv) un proceso de verificación, evaluación y valoración regulares
            de la eficacia de las medidas técnicas y organizativas para
            garantizar la seguridad del Tratamiento. No obstante, en el caso en
            que tengamos alguna vulneración a nuestra seguridad, te
            notificaremos en el momento inmediato en el que tengamos
            conocimiento de dicha vulneración, cuando ésta afecte de forma
            significativa tus derechos patrimoniales o morales, a fin de que
            puedas tomar las medidas correspondientes en defensa de tus
            derechos.
          </p>
          <h2>Datos personales sensibles que se tratarán</h2>
          <p>
            Son datos personales sensibles aquellos que afectan la esfera más
            íntima de su Titular, o cuya utilización indebida pueda dar origen a
            discriminación o conlleve un riesgo grande para éste. En particular
            se consideran sensibles aquellos que puedan revelar aspectos como
            origen racial o étnico, estado de salud presente y futuro,
            información genética, creencias religiosas, filosóficas y morales,
            afiliación sindical, opiniones políticas o preferencia sexual ("
            <strong>Datos Personales Sensibles</strong>").
          </p>
          <p>
            En SATWS, no utilizamos Datos Personales Sensibles. Si por alguna
            causa llegaras a proporcionarnos alguno de estos datos, los mismos
            se utilizarán exclusivamente en la medida que lo hayas autorizado y
            por el tiempo mínimo indispensable.
          </p>
          <h2>Finalidades del tratamiento</h2>
          <h3>a. Primarias</h3>
          <p>
            En SATWS recabamos tus Datos Personales con las siguientes
            finalidades: (i) identificar y gestionar el registro de tu cuenta
            (la "<strong>Cuenta de Usuario</strong>"), (ii) atender las
            preguntas de nuestros usuarios, (iii) llevar a cabo las gestiones
            necesarias para la formalización de contratos, (iv) procesamiento,
            actualización, modificación, cancelación y/o confirmación de los
            servicios, productos, e información enviada por SATWS a los
            Titulares a través de la Plataforma, llamadas telefónicas y/o correo
            electrónico, (v) dar cumplimiento a las obligaciones contraidas por
            el Titular con SATWS, (vi) dar respuesta a las solicitudes de
            ejercicio de cancelación del consentimiento de Datos Personales o
            Derechos Arco de los Titulares.
          </p>
          <h3>b. Secundarias</h3>
          <p>
            Asimismo, los Datos Personales recabados, podrán ser utilizados para
            las siguientes finalidades adicionales que no se relacionan
            directamente con los servicios prestados o productos ofrecidos, pero
            que nos ayudan a brindar una mejor atención a los titulares: (i)
            mejorar, perfeccionar, modificar, ampliar y desarrollar nuestros
            servicios y/o productos, (iii) desarrollar nuevos servicios y/o
            productos, (iv) evaluación de calidad de los servicios y/o productos
            de SATWS, (ii) recepción de noticias, ofertas, promociones e
            información de SATWS, (iii) envío de boletines informativos, así
            como (iv) envío de información con fines de mercadotecnia,
            publicidad y prospección comercial.
          </p>
          <p>
            Como Responsables de tus Datos Personales, procuraremos que los
            mismos sean pertinentes, correctos y se mantengan actualizados.
            Cuando tus Datos Personales dejen de ser necesarios para el
            cumplimiento de las finalidades previstas en el presente Aviso, así
            como las disposiciones legales aplicables, procederemos a la
            cancelación de los mismos.
          </p>
          <h2>Transferencias de datos personales que se efectúen</h2>
          <p>
            Nos comprometemos a no transferir tus Datos Personales a Terceros,
            sin tu previo consentimiento, salvo las excepciones previstas en el
            artículo 37 de la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares: (i) cuando la transferencia esté
            prevista en una Ley o Tratado en los que México sea parte; (ii)
            cuando la transferencia sea necesaria para la prevención o el
            diagnóstico médico, la prestación de asistencia sanitaria,
            tratamiento médico o la gestión de servicios sanitarios; (iii)
            cuando la transferencia sea efectuada a sociedades controladoras,
            subsidiarias o afiliadas bajo el control común del responsable, o a
            una sociedad matriz o a cualquier sociedad del mismo grupo del
            responsable que opere bajo los mismos procesos y políticas internas;
            (iv) cuando la transferencia sea necesaria por virtud de un contrato
            celebrado o por celebrar en interés del Titular, por el Responsable
            y un Tercero; (v) cuando la transferencia sea necesaria o legalmente
            exigida para la salvaguarda de un interés público, o para la
            procuración o administración de justicia; (vi) Cuando la
            transferencia sea precisa para el reconocimiento, ejercicio o
            defensa de un derecho en un proceso judicial, y (vii) Cuando la
            transferencia sea precisa para el mantenimiento o cumplimiento de
            una relación jurídica entre el Responsable y el Titular.
          </p>
          <p>
            Si el Titular llegara a proporcionar Datos Personales de terceros,
            manifiesta expresamente que cuenta con el consentimiento expreso de
            sus clientes/usuarios, así como el correspondiente aviso de
            privacidad de conformidad con lo dispuesto en la legislación
            mexicana vigente.
          </p>
          <h2>
            Opciones y medios que el responsable ofrezca a los titulares para
            limitar el uso o divulgación de sus datos personales
          </h2>
          <p>
            Para limitar el uso o divulgación de tus Datos Personales, no
            recibir promociones de nuestros productos y/o servicios, puedes
            realizar la inscripción gratuita en nuestros listados internos de
            exclusión, siguiendo el mismo procedimiento que se indica en la
            Sección denominada "
            <strong>MEDIOS PARA EL EJERCICIO DE LOS DERECHOS ARCO</strong>"
            siguiente del presente Aviso.
          </p>
          <h2>
            Medios para el ejercicio de los derechos arco (acceso,
            rectificación, cancelación u oposición)
          </h2>
          <p>
            En cualquier momento podrás acceder, rectificar, cancelar u oponerte
            al uso de tus datos personales enviando tu solicitud al siguiente
            correo electrónico{" "}
            <a href="mailto:privacidad@sat.ws">privacidad@sat.ws</a>, que
            corresponde al departamento encargado de los Datos Personales dentro
            de SATWS (el "<strong>Encargado</strong>"). Dicha solicitud deberá
            contener lo siguiente: (i) nombre del Titular y domicilio u otro
            medio para comunicar la respuesta a tu solicitud; (ii) documentos
            que acrediten tu identidad o la representación legal del Titular;
            (iii) la descripción clara y precisa de los datos personales
            respecto de los cuales deseas ejercer los derechos ARCO; (iv)
            cualquier otro documento que facilite la localización de los Datos
            Personales, y (v) en caso de rectificación la documentación que
            sustente tu petición.
          </p>
          <p>
            Nosotros te comunicaremos la determinación adoptada en un plazo
            máximo de 20 (veinte) días hábiles a partir de que recibamos la
            solicitud para que en caso de resultar procedente podamos hacer
            efectiva la misma en un plazo de 15 (quince) días hábiles a partir
            de que comuniquemos dicha respuesta. Cuando las circunstancias lo
            justifiquen, los plazos referidos podrán ampliarse por periodos
            iguales.
          </p>
          <h2>Limitación y portabilidad de tus datos personales</h2>
          <p>
            En cualquier momento podrás limitar el Tratamiento de tus Datos
            Personales, así como a solicitar que los transmitamos, siguiendo el
            mismo procedimiento en el apartado "
            <strong>MEDIOS PARA EL EJERCICIO DE LOS DERECHOS ARCO</strong>"
            anterior.
          </p>
          <p>
            Cuando la limitación impida la prestación de un servicio que hayas
            contratado con nosotros, el Tratamiento continuará conforme lo hayas
            autorizado hasta la finalización del servicio.
          </p>
          <h2>
            Mecanismos y procedimientos para la revocación del consentimiento
          </h2>
          <p>
            En todo momento podrás manifestar tu negativa al presente Aviso,
            mediante notificación al correo electrónico{" "}
            <a href="mailto:privacidad@sat.ws">privacidad@sat.ws</a>, del
            Encargado.
          </p>
          <h2>
            Uso de <em>cookies</em> web beacons y otras tecnologías de rastreo
          </h2>
          <p>
            Además de los Datos Personales, mecionados en el presente Aviso, en
            tu ordenador se almacenan <em>cookies</em> cuando visitas y usas
            nuestra Plataforma.
          </p>
          <p>
            Las <em>cookies</em> son pequeños archivos de texto que se almacenan
            en el navegador de tu dispositivo para guardar determinada
            información. Cuando vuelvas a visitar nuestra Plataforma desde el
            mismo dispositivo, la información guardada en las <em>cookies</em>{" "}
            se transmitirá a nuestra Plataforma ("
            <strong>Cookies Propias</strong>") o bien a otro sitio web al que
            pertenezca la <em>cookie</em> ("
            <strong>Cookies de Terceros</strong>").
          </p>
          <p>
            Mediante la información guardada y enviada a la Plataforma,
            reconocemos que has accedido al sitio y lo has visitado con el
            navegador que usas en ese dispositivo. Usamos esta información para
            poder diseñar y mostrar la Plataforma de manera óptima de acuerdo a
            tus preferencias. En este sentido, en tu dispositivo solo se
            identifica la cookie. Aparte de esto, tus Datos Personales
            únicamente se guardarán con tu consentimiento expreso o cuando sea
            estrictamente necesario para poder usar el servicio que se te ofrece
            y al que accedes por consiguiente.
          </p>
          <p>
            A continuación enlistamos los diferentes tipos de <em>cookies</em> ,
            cuyo ámbito y funcionalidad explicaremos más adelante:
          </p>
          <ol>
            <li>
              <em>Cookies</em> estrictamente necesarias (tipo A)
            </li>
            <li>
              <em>Cookies</em> de funcionalidad y rendimiento (tipo B)
            </li>
            <li>
              <em>Cookies</em> con fines mercadotécnicos, publicitarios y/o de
              prospección comercial (p. ej. de marketing) (tipo C)
            </li>
          </ol>
          <h3>Cookies estrictamente necesarias (tipo A)</h3>
          <p>
            Las <em>cookies</em> estrictamente necesarias garantizan funciones
            sin las que no podrías usar nuestra página web como está previsto.
            Seremos los únicos en usar estas <em>cookies</em> , por lo que se
            trata de <em>cookies</em> propias. Esto implica que toda la
            información almacenada en las <em>cookies</em> será enviada a
            nuestra Plataforma.
          </p>
          <p>
            Las <em>cookies</em> estrictamente necesarias sirven, por ejemplo,
            para garantizar que cuando inicies sesión como usuario registrado,
            tu sesión siga activa cuando accedas a diferentes subpáginas de
            nuestra Plataforma, es decir, que no tengas que volver a introducir
            tus datos de inicio de sesión cada vez que accedas a una nueva
            página.
          </p>
          <p>
            El uso de <em>cookies</em> estrictamente necesarias en nuestra
            Plataforma es posible sin tu consentimiento. Por ello, las{" "}
            <em>cookies</em> estrictamente necesarias no se pueden activar ni
            desactivar individualmente, aunque sí puedes desactivar las{" "}
            <em>cookies</em> en tu navegador en cualquier momento (véase cómo
            desactivarlas un poco más abajo).
          </p>
          <p>
            Asimismo, en el presente Aviso podrás encontrar más información
            sobre los tipos de <em>cookies</em> configurados y que se emplean en
            las herramientas implementadas en nuestra Plaforma.
          </p>
          <h3>Cookies de funcionalidad y rendimiento (tipo B)</h3>
          <p>
            Las <em>cookies</em> de funcionalidad permiten que nuestra
            Plataforma almacene información ya facilitada (como usuario
            registrado o selección de idioma) con el objeto de ofrecerte
            funciones ampliadas y más personalizadas en base a esta información.
            Estas <em>cookies</em> recogen y almacenan únicamente información
            anónima, por lo que no pueden rastrear tus movimientos en otros
            sitios web.
          </p>
          <p>
            Las <em>cookies</em> de rendimiento recogen información sobre cómo
            se usa nuestra Plataforma con el fin de mejorar su atractivo, su
            contenido y su funcionalidad.{" "}
          </p>
          <p>
            Estas <em>cookies</em> nos ayudan, por ejemplo, a determinar si se
            visitan subpáginas de nuestra Plataforma y cuáles, y en qué
            contenidos están especialmente interesados los usuarios. En
            concreto, registramos el número de visitas a una página, el número
            de subpáginas a las que se ha accedido, el tiempo de permanencia en
            nuestra Plataforma, en qué orden se visitan las páginas, qué
            palabras de búsqueda le han conducido hasta nosotros, el país, la
            región y, en su caso, la ciudad desde los que se accede, así como la
            proporción de dispositivos móviles que acceden a nuestros sitios
            web. También capturamos movimientos, clicks y desplazamientos con el
            ratón del ordenador para conocer las áreas de nuestra Plataforma que
            son de especial interés para los usuarios.
          </p>
          <p>
            Gracias a ello podemos adaptar los contenidos de nuestra Plataforma
            más específicamente a las necesidades de nuestros usuarios y
            optimizar nuestra oferta. La dirección IP de tu ordenador
            transmitida por razones técnicas se convierte en anónima de manera
            automática y no nos permite extraer ninguna conclusión sobre el
            usuario individual.
          </p>
          <p>
            Para revisar y actualizar cuando lo desees tu consentimiento y
            ajustes definitivos en el sistema de almacenamiento y uso de{" "}
            <em>cookies</em> sigue leyendo el presente Aviso.
          </p>
          <h3>
            Cookies con fines mercadotécnicos, publicitarios y/o de prospección
            comercial (tipo C)
          </h3>
          <p>
            Las <em>cookies</em> que no son estrictamente necesarias (tipo A) ni
            de funcionalidad o rendimiento (tipo B), p. ej. Las <em>cookies</em>{" "}
            de marketing.
          </p>
          <p>
            Nos reservamos igualmente el derecho a usar información que hayamos
            obtenido por medio de <em>cookies</em> derivada de un análisis
            anónimo del comportamiento de uso de los visitantes de nuestra
            Plataforma con el fin de mostrar publicidad concreta de algunos de
            nuestros productos en nuestros sitios web. Creemos que esto te
            beneficia como usuario, ya que te mostramos publicidad o contenidos
            que a nuestro parecer se acomodan a tus intereses según tu
            comportamiento de navegación, de modo que veas menos publicidad
            aleatoria o ciertos contenidos que puedan resultarte menos
            interesantes.
          </p>
          <p>
            Las <em>cookies</em> tipo C, que son las que proceden de empresas
            anunciantes externas (cookies de terceros) y se usan para recoger
            información acerca de los sitios web que el usuario visita con el
            fin de generar publicidad relevante en base a sus intereses o los de
            otros usuarios similares.
          </p>
          <h3>
            Administración y eliminación de todas las <em>cookies</em>{" "}
          </h3>
          <p>
            Puedes configurar tu navegador de modo que se impida de manera
            general que se guarden <em>cookies</em> en tu dispositivo y/o que se
            te pregunte en cada ocasión si estás de acuerdo con permitir el uso
            de <em>cookies</em> . También puedes eliminar en cualquier momento
            las <em>cookies</em> que hayan vuelto a ser activadas. Para saber
            con detalle cómo funciona este procedimiento puedes acudir a la
            función de ayuda de tu navegador.
          </p>
          <p>
            Debes tener en cuenta que la desactivación general de{" "}
            <em>cookies</em> puede causar restricciones funcionales en nuestra
            Plataforma. No obstante, puedes bloquearlas o deshabilitarlas
            activando la configuración de tu navegador que te permite rechazar
            la instalación de todas las <em>cookies</em>o de algunas de ellas.
          </p>
          <p>
            La mayoría de los navegadores permiten advertir de la presencia de
            <em>cookies</em> o rechazarlas automáticamente. Si las rechazas
            podrás seguir usando nuestra Plataforma, aunque el uso de algunos de
            tus servicios podrá ser limitado y por tanto tu experiencia en
            nuestra Plataforma menos satisfactoria.
          </p>
          <p>
            Si queres retirar en cualquier momento tu consentimiento relacionado
            con el presente Aviso, deberás eliminar las <em>cookies</em>{" "}
            almacenadas en tu equipo (ordenador o dispositivo móvil) a través de
            los ajustes y configuraciones de tu navegador de Internet.
          </p>
          <p>
            Para mayor información sobre la eliminación, deshabilitación o
            bloqueo de las <em>cookies</em> por favor visita:{" "}
            <a
              href="http://www.allaboutcookies.org/es/administrar-las-cookies"
              target="_blank"
            >
              http://www.allaboutcookies.org/es/administrar-las-cookies
            </a>
          </p>
          <p>
            Salvo que hayas ajustado la configuración de tu navegador, nuestro
            sistema creará <em>cookies</em> en cuanto visites nuestra
            Plataforma. Ten presente que todos los navegadores de Internet
            permiten el cambio de dicha configuración. Para más información
            sobre la forma de ajustar tus configuraciones de <em>cookies</em> en
            los siguientes navegadores, te remitimos al enlace pertinente:
          </p>
          <p>
            Microsoft Edge
            <br />
            <a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">
              https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy
            </a>
          </p>
          <p>
            FireFox
            <br />
            <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">
              https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we
            </a>
          </p>
          <p>
            Chrome
            <br />
            <a href="https://support.google.com/chrome/answer/95647?hl=es">
              https://support.google.com/chrome/answer/95647?hl=es
            </a>
          </p>
          <p>
            Safari
            <br />
            <a href="http://www.apple.com/es/privacy/use-of-cookies/">
              www.apple.com/es/privacy/use-of-cookies/
            </a>
          </p>
          <p>
            También puedes gestionar el almacén de <em>cookies</em> desde otras
            herramientas como:
          </p>
          <p>
            Ghostery
            <br />
            <a href="http://www.ghostery.com">www.ghostery.com</a>
          </p>
          <p>
            Your online choices
            <br />
            <a href="http://www.youronlinechoices.com/es">
              www.youronlinechoices.com/es
            </a>
          </p>
          <h3>Análisis de páginas web</h3>
          <p>
            Esta Plataforma emplea Google Analytics, un servicio de análisis de
            sitios web de Google Inc. ("<strong>Google</strong>"). Google
            Analytics utiliza un tipo específico de <em>cookie</em> que se
            almacena en tu ordenador y posibilita el análisis del uso que haces
            de nuestra Plataforma. La información relativa al uso que haces de
            esta Plataforma generada por la <em>cookie</em> suele transmitirse a
            un servidor de Google en EE.UU., donde se almacena.
          </p>
          <p>
            Queremos advertir que Google Analytics ha sido integrado en esta
            Plataforma para que incluya el código "gat._anonymizeIp();" con el
            fin de garantizar que el registro de direcciones IP se realiza de
            manera anónima (el llamado enmascaramiento de IP). Debido a la
            anonimización de IP de este sitio web, Google abrevia su dirección
            IP dentro del territorio de la UE y los Estados parte del Espacio
            Económico Europeo. La dirección IP completa solo se transmite a un
            servidor de Google en EE.UU. para ser abreviada allí en casos
            excepcionales. Google se ha comprometido a respetar el Escudo de
            Privacidad UE EE.UU. (
            <a href="https://www.privacyshield.gov/EU-US-Framework">
              https://www.privacyshield.gov/EU-US-Framework
            </a>
            ).
          </p>
          <p>
            Google utiliza esta información por cuenta nuestra para analizar el
            uso que haces de esta Platadorma con el fin de recopilar informes
            acerca de las actividades en la Plataforma y ofrecer servicios
            adicionales relacionados con el uso de sitios web e Internet. Google
            también puede transferir esta información a terceros con arreglo a
            la Ley o en caso de que dichos terceros realicen el tratamiento de
            estos datos por cuenta de Google. La dirección IP transmitida por Tu
            navegador en el marco de Google Analytics no se fusiona con otros
            datos de Google.
          </p>
          <p>
            Puedes evitar el almacenamiento de <em>cookies</em> estableciendo la
            configuración adecuada usando el software de tu navegador. Además,
            puedes evitar que Google registre los datos relacionados con tu uso
            de esta Plataforma que genera la <em>cookie</em> (incluida tu
            dirección IP) y realice el tratamiento de dichos datos instalando el
            plugin del navegador que puede descargarse en{" "}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=es">
              https://tools.google.com/dlpage/gaoptout?hl=es
            </a>
            .
          </p>
          <h2>
            Procedimiento y medio para comunicar los cambios en el aviso de
            privacidad
          </h2>
          <p>
            Constantemente tratamos de mejorar nuestros servicios, por lo que
            nos reservamos el derecho de cambiar este Aviso de Privacidad en
            ocasiones, por lo que te alertaremos de cualquier cambio mediante un
            correo electrónico. Para poder continuar utilizando nuestros
            servicios deberás aceptar cada actualización en nuestro Aviso.
          </p>
          <h2>Menores de edad</h2>
          <p>
            En SATWS no recabamos Datos Personales de menores de 18 años. Si
            eres menor de edad y por algún motivo nos proporcionas esta
            información, al momento de darnos cuenta mediante nuestro proceso de
            verificación procederemos a la cancelación inmediata de tus datos
            personales.
          </p>

          <p>
            Si crees que podría obrar en nuestros registros Datos Personales
            procedentes de un menor de edad, te pedimos ponerte en contacto con
            nosotros a través de un correo electrónico a la siguiente dirección{" "}
            <a href="mailto:privacidad@sat.ws">privacidad@sat.ws</a>.
          </p>
        </Wrapper>
      </div>
    </Layout>
  )
}
